<template>
  <div class="product-item" @click="openProduct">
    <div class="product-item__wrapper">
      <div class="product-item__image _ibg">
        <img
          v-if="product.main_image != null"
          :src="cdn + product.main_image"
          alt=""
        />
        <img
          v-else
          :src="require('../assets/images/logo/nophoto.png')"
          alt=""
        />
      </div>
    </div>
    <div class="product-item__info">
      <div class="product-item__name" v-if="title">{{ title }}</div>
      <div class="product-item__name" v-else>{{ product.title }}</div>
      <div class="product-item__bottom">
        <div class="product-item__price">{{ formatPrice }}</div>
        <button class="product-item__btn" :disabled="product.stock === 0" :class="{disbutton: product.stock === 0}" @click="addProduct" ref="addBtn">
          <img src="../assets/icons/card/white-card.svg" alt="" />
          <img src="../assets/icons/card/tick.svg" alt="" />
        </button>
      </div>
    </div>
    <button
      class="product-item__icon"
      ref="favoriteBtn"
      @click="addFavoriteProduct"
      :class="{ active: favorite || isFavorites }"
    >
      <span>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_514_7172)">
            <path
              d="M2.04673 11.0474L9.99935 19L17.952 11.0474C18.836 10.1633 19.3327 8.96429 19.3327 7.71404C19.3327 5.11055 17.2221 3 14.6186 3C13.3684 3 12.1694 3.49666 11.2853 4.38071L9.99935 5.66667L8.71339 4.38071C7.82934 3.49666 6.6303 3 5.38006 3C2.77657 3 0.666016 5.11055 0.666016 7.71404C0.666016 8.96429 1.16267 10.1633 2.04673 11.0474Z"
              fill="none"
            />
            <path
              d="M14.5835 1.59711C13.6451 1.6117 12.7272 1.87365 11.9224 2.3565C11.1177 2.83935 10.4546 3.526 10.0001 4.34711C9.54566 3.526 8.88257 2.83935 8.07783 2.3565C7.27308 1.87365 6.35517 1.6117 5.41679 1.59711C3.92091 1.6621 2.51155 2.31649 1.49661 3.4173C0.481678 4.51812 -0.0563308 5.97588 0.000128003 7.47211C0.000128003 11.2613 3.98846 15.3996 7.33346 18.2054C8.08031 18.833 9.02459 19.1771 10.0001 19.1771C10.9757 19.1771 11.9199 18.833 12.6668 18.2054C16.0118 15.3996 20.0001 11.2613 20.0001 7.47211C20.0566 5.97588 19.5186 4.51812 18.5036 3.4173C17.4887 2.31649 16.0793 1.6621 14.5835 1.59711ZM11.596 16.9304C11.1493 17.3066 10.5841 17.5129 10.0001 17.5129C9.41617 17.5129 8.85097 17.3066 8.40429 16.9304C4.12263 13.3379 1.66679 9.89127 1.66679 7.47211C1.60983 6.4177 1.9721 5.38355 2.6746 4.59519C3.37709 3.80683 4.36282 3.32823 5.41679 3.26377C6.47077 3.32823 7.45649 3.80683 8.15899 4.59519C8.86149 5.38355 9.22376 6.4177 9.16679 7.47211C9.16679 7.69312 9.25459 7.90508 9.41087 8.06136C9.56715 8.21764 9.77911 8.30544 10.0001 8.30544C10.2211 8.30544 10.4331 8.21764 10.5894 8.06136C10.7457 7.90508 10.8335 7.69312 10.8335 7.47211C10.7765 6.4177 11.1388 5.38355 11.8413 4.59519C12.5438 3.80683 13.5295 3.32823 14.5835 3.26377C15.6374 3.32823 16.6232 3.80683 17.3257 4.59519C18.0282 5.38355 18.3904 6.4177 18.3335 7.47211C18.3335 9.89127 15.8776 13.3379 11.596 16.9271V16.9304Z"
              fill="#727272"
            />
          </g>
          <defs>
            <clipPath id="clip0_514_7172">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </span>
    </button>
    <div
      v-if="sale || product.funds_1 || product.funds_2 || false"
      class="sale"
    >
      {{ $t("sale") }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  props: {
    product: {
      type: Object,
    },
    title: {
      type: String,
    },
    currentPage: {
      type: Number,
    },
    isFavorites: {
      type: Boolean,
      required: false,
      default: false,
    },
    sale: {
      type: Boolean,
      required: false,
      default: null,
    },
    category: {
      type: Array,
      required: false,
      default: null,
    },
    filters: {
      type: Array,
      required: false,
      default: null,
    },
    brands: {
      type: Array,
      required: false,
      default: null,
    },
  },

  computed: {
    ...mapState(["auth", "favorites", "cdn"]),
    formatPrice() {
      return (
        this.product.current_price
          ?.toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " ₸"
      );
    },
    favorite() {
      return this.favorites.some(
        (item) => parseInt(item.product_id) === this.product.id
      );
    },
  },

  methods: {
    ...mapMutations(["addFavoriteToLocal", "addToCart", "SET_PRODUCT"]),
    ...mapActions(["addFavorite", "removeFavorite", "fetchFavorites"]),
    addProduct() {
      const btn = this.$refs.addBtn;
      btn.classList.add("_active");
      setTimeout(() => {
        btn.classList.remove("_active");
      }, 2000);
      this.addToCart({ ...this.product, typeBuy: 1 });
    },
    addFavoriteProduct() {
      if (this.auth) {
        let productId = !this.isFavorites ? this.product.id : this.product.product_id;
        let favoriteProduct = this.favorites.find(
          (product) => parseInt(product.product_id) == productId
        );
        if (favoriteProduct) {
          this.removeFavorite(favoriteProduct.id);
        } else {
          this.addFavorite({ product_id: this.product.id });
        }
        this.fetchFavorites();
        this.$emit("change");
      } else {
        this.addFavoriteToLocal(this.product);
      }
    },
    openProduct(e) {
      const favoriteBtn = this.$refs.favoriteBtn;
      const addBtn = this.$refs.addBtn;
      if (
        e.target != addBtn &&
        !addBtn.contains(e.target) &&
        e.target != favoriteBtn &&
        !favoriteBtn.contains(e.target)
      ) {
        this.SET_PRODUCT(this.product.id);
        this.category?.length
          ? this.$router.push({
              name: "ProductCard",
              params: { slug: this.product.slug },
              query: {
                page: this.currentPage,
                category: this.category.join(","),
                filters: this.filters.join(","),
                brands: this.brands.join(","),
              },
            })
          : this.$router.push({
              name: "ProductCard",
              params: { slug: this.product.slug },
              query: { page: this.currentPage },
            });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-item {
  height: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 59px 8px 27px;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  border-radius: 10px;
  transition: all 0.3s ease;

  @media (max-width: $mobileSmall + px) {
    padding: 40px 18px 28px 10px;
  }

  &__wrapper {
    @media (max-width: $mobileSmall + px) {
      padding: 0 1.21px 0 9.21px;
    }
  }

  &__image {
    width: 100%;
    max-width: 260px;
    padding-bottom: 70.3%;
    margin: 0 auto 20px;
    @media (max-width: $tablet + px) {
      margin: 0 auto 14px;
    }
    @media (max-width: $mobileSmall + px) {
      margin: 0 auto 8px;
    }

    &._ibg {
      img {
        object-fit: contain;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    height: 100%;
    @media (min-width: $mobileSmall + px) {
      padding: 0 17px 0 12px;
    }
  }

  &__name {
    flex: 1 0 auto;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin: 0 0 23px 0;
    display: -webkit-box;
    overflow: hidden; /* Скрытие излишнего текста, выходящего за пределы блока */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis; /* Замена излишнего текста на многоточие */
    @media (max-width: $tablet + px) {
      margin: 0 0 21px 0;
      font-size: 15px;
    }
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 19px 0;
      font-size: 14px;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__price {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }

  &__icon {
    position: absolute;
    top: 20px;
    right: 19px;
    transition: all 0.3s ease 0s;

    svg {
      display: inline-block;
      width: 20px;
      height: 20px;
      transition: all 0.5s ease 0s;

      &:hover {
        path {
          fill: #d81921;
        }
      }
    }

    &.active {
      animation: heartPulse 0.5s ease both;
      svg {
        path {
          fill: #d81921;
        }
      }
    }

    @keyframes heartPulse {
      0% {
        transform: scale(0);
      }
      50% {
        transform: scale(1.3);
      }
    }

    @media (max-width: $mobileSmall + px) {
      img {
        width: 19.22px;
        height: 17.58px;
      }
      top: 15px;
      right: 14.41px;
    }
  }

  &__btn {
    cursor: pointer;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background: #d81921;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      transition: all 0.45s ease 0s;

      &:last-child {
        position: absolute;
        transition: all 0.3s ease 0.2s;
        transform: scale(0);
      }
    }

    &._active {
      background: #d81921;

      img {
        &:first-child {
          transform: translateX(35px);
        }

        &:last-child {
          transform: scale(1);
        }
      }
    }

    &:hover {
      box-shadow: 0px 4px 14px rgba(108, 78, 153, 0.5);
    }

    @media (max-width: $tablet + px) {
      width: 49px;
      height: 49px;
      img {
        width: 19px;
        height: 19px;
      }
    }

    @media (max-width: $mobileSmall + px) {
      width: 46px;
      height: 46px;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
}
.sale {
  position: absolute;
  top: 20px;
  left: -5px;
  min-width: 78px;
  height: 24px;
  background: url("../assets/images/sale.png");
  background-repeat: no-repeat;
  font-weight: 500;
  font-size: 17px;
  color: #ffffff;
  padding-left: -5px;
  padding-top: 2px;
  text-align: center;
}

.disbutton {
  background: #c2c2c2;
  pointer-events: none;
}
</style>